@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+HK:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jersey+10&family=Noto+Sans+HK:wght@100..900&display=swap');

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #ffffff;
}

#root {
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: 0 20px;
  font-family: 'Noto Sans HK', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

h1 {
  font-family: 'Jersey 10', sans-serif;
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #00ff00;
  text-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
}

p {
  font-size: 1.5rem;
  max-width: 600px;
  line-height: 1.6;
}

.countdown {
  font-size: 2rem;
  margin-top: 2rem;
  font-weight: bold;
  color: #00ff00;
  font-family: 'Jersey 10', sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-size: 42px;
  font-style: normal;
}

@media (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.2rem;
  }

  .countdown {
    font-size: 1.5rem;
  }
}
