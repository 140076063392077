.user-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.user-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary-color);
}

.user-avatar-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.username {
  font-weight: bold;
}
