.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 20px; /* Add left padding */
  padding-right: 20px; /* Add right padding for consistency */
}

.user-info {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.login-box {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-box form {
  display: flex;
  flex-direction: column;
}

.login-box input,
.login-box button {
  margin-bottom: 10px;
  padding: 10px;
}

.social-login {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.social-login button {
  flex: 1;
  margin: 0 5px;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header.sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-content {
  flex-grow: 1;
  padding-top: 20px; /* Add some padding to account for the sticky header */
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Modern Gaming Theme */
:root {
  --primary-color: #7289da;
  --secondary-color: #99aab5;
  --background-color: #2c2f33;
  --text-color: #ffffff;
  --card-background: rgba(44, 47, 51, 0.8);
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.app-container {
  background: linear-gradient(135deg, #1a1c20 0%, #2c2f33 100%);
}

.header.sticky {
  background-color: var(--background-color);
  border-bottom: 2px solid var(--primary-color);
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card {
  background-color: var(--card-background);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image-container {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 1.2rem;
  margin: 10px;
  color: var(--primary-color);
}

.card-description {
  font-size: 0.9rem;
  margin: 0 10px 10px;
  color: var(--secondary-color);
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.card-username {
  font-size: 0.8rem;
  color: var(--secondary-color);
}

.star-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--secondary-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.star-button.favorite {
  color: #ffd700;
}

/* Update existing styles */
.login-box {
  background-color: var(--card-background);
  color: var(--text-color);
}

.login-box input,
.login-box button,
.social-login button {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--primary-color);
}

.login-box button:hover,
.social-login button:hover {
  background-color: var(--primary-color);
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.build-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.build-description {
  margin-bottom: 20px;
}

.user-link {
  color: var(--primary-color);
  text-decoration: none;
}

.build-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.build-summary > div {
  flex: 1;
  margin: 0 10px;
}

.total-price {
  font-size: 1.2rem;
  font-weight: bold;
}

.performance-scale {
  font-size: 1.2rem;
}

.components-table {
  width: 100%;
  border-collapse: collapse;
}

.components-table th,
.components-table td {
  padding: 10px;
  border: 1px solid var(--secondary-color);
}

.components-table th {
  background-color: var(--primary-color);
  color: var(--text-color);
}

.components-table a {
  color: var(--primary-color);
  text-decoration: none;
}

.components-table a:hover {
  text-decoration: underline;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.logo-link:hover {
  opacity: 0.8;
}

.user-profile {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.user-header {
  text-align: center;
  margin-bottom: 30px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
  object-fit: cover;
  border-radius: 50%;
}

.user-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 15px 0;
}

.follow-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.follow-button:hover {
  background-color: #5a6ebd;
}

.user-builds {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.login-button {
  background-color: var(--primary-color);
  color: var(--text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #5a6ebd;
}

.feed {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.my-rigs,
.favorite-rigs {
  margin-bottom: 40px;
}

.card-actions {
  display: flex;
  gap: 10px;
}

.like-button,
.favorite-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.like-button:hover,
.favorite-button:hover {
  transform: scale(1.1);
}

/* Add this new section for the rig section headings */
.rig-section-heading {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Update the existing styles for consistency */
.feed h2,
.my-rigs h2,
.favorite-rigs h2 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Add this to your existing styles */
.logo {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.add-rig-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-rig-button:hover {
  background-color: #0056b3;
}
