.performance-scale {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.performance-scale h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: center;
}

.scale-container {
  background-color: rgba(255, 255, 255, 0.1);
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.scale-bar {
  background: linear-gradient(90deg, #ff4e50, #f9d423);
  height: 100%;
  border-radius: 15px;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.scale-score {
  color: #ffffff;
  font-weight: bold;
  padding-right: 10px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.scale-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: var(--secondary-color);
  font-size: 0.8rem;
}
