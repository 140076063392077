.total-price {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
}

.total-price h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
  text-align: center;
}

.price-container {
  background-color: rgba(255, 255, 255, 0.1);
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-value {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
