.card {
  background-color: var(--card-background);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.card:hover {
  transform: translateY(-5px);
}

.card-link {
  text-decoration: none;
  color: inherit;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.card-image-container {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-title {
  font-size: 1.2rem;
  margin: 10px;
  color: var(--primary-color);
}

.card-description {
  font-size: 0.9rem;
  margin: 0 10px 10px;
  color: var(--secondary-color);
  flex-grow: 1;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.1);
}

.card-username {
  font-size: 0.8rem;
  color: var(--secondary-color);
}

.card-actions {
  display: flex;
  gap: 10px;
}

.like-button,
.favorite-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.like-button:hover,
.favorite-button:hover {
  transform: scale(1.1);
}

.favorite-button {
  transition: transform 0.2s ease, color 0.3s ease;
}

.favorite-button.filled {
  color: gold;
  animation: fill 0.3s forwards;
}

@keyframes fill {
  from {
    color: #ccc;
  }
  to {
    color: gold;
  }
}

.likes-count {
  color: white;
  font-size: 0.9rem; /* Adjust this value as needed */
}
