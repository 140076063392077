.add-rig-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.add-rig-modal input,
.add-rig-modal textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-rig-modal button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-rig-modal button:hover {
  background-color: #f0f0f0;
}

.add-rig-modal h2,
.add-rig-modal h3 {
  margin-bottom: 10px;
}

.add-rig-modal div {
  margin-bottom: 10px;
}

/* Add styles for the modal background */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-rig-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--card-background);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-rig-container h2,
.add-rig-container h3 {
  color: var(--primary-color);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.add-rig-container input,
.add-rig-container textarea {
  width: 100%;
  margin-bottom: 10px;
  padding: 15px; /* Add padding */
  margin: 5px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.add-rig-container textarea {
  height: 100px;
}

.component-inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.component-inputs input {
  flex: 1;
}

.add-component-button,
.submit-button,
.close-button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--text-color);
  transition: background-color 0.3s ease;
}

.add-component-button:hover,
.submit-button:hover,
.close-button:hover {
  background-color: #5a6ebd;
}
